import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const query = graphql`
  {
    site {
      siteMetadata {
        author
        siteDesc: description
        image
        favicon
        keywords
        siteUrl
        siteTitle: title
        twitterUsername
        facebookid
      }
    }
  }
`

const SEO = ({ title, description }) => {
  const { site } = useStaticQuery(query)
  const {
    siteDesc,
    siteTitle,
    siteUrl,
    keywords,
    image,
    favicon,
    twitterUsername,
    facebookid
  } = site.siteMetadata
  return (
    <Helmet htmlAttributes={{ lang: "es" }} title={`${title} | ${siteTitle}`}>
      <meta name="description" content={description || siteDesc} />
      <meta name="image" content={`${siteUrl}${image}`} />
      <meta name="author" content="Neurona Servicios"/>

      <link rel="apple-touch-icon" sizes="57x57" href="/favicon-57x57.png"/>
      <link rel="apple-touch-icon" sizes="60x60" href="/favicon-60x60.png"/>
      <link rel="apple-touch-icon" sizes="72x72" href="/favicon-72x72.png"/>
      <link rel="apple-touch-icon" sizes="76x76" href="/favicon-76x76.png"/>
      <link rel="apple-touch-icon" sizes="114x114" href="/favicon-114x114.png"/>
      <link rel="apple-touch-icon" sizes="120x120" href="/favicon-120x120.png"/>
      <link rel="apple-touch-icon" sizes="144x144" href="/favicon-144x144.png"/>
      <link rel="apple-touch-icon" sizes="152x152" href="/favicon-152x152.png"/>
      <link rel="apple-touch-icon" sizes="180x180" href="/favicon-180x180.png"/>
      <link rel="icon" type="image/png" sizes="192x192"  href="/favicon-192x192.png"/>
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png"/>
      <link rel="icon" type="image/png" sizes="96x96" href="/favicon-96x96.png"/>
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png"/>
      <link rel="manifest" href="/manifest.json"/>
      <meta name="msapplication-TileColor" content="#ffffff"/>
      <meta name="msapplication-TileImage" content="/ms-icon-144x144.png"/>
      <meta name="theme-color" content="#ffffff"/>

      <meta name="keywords" content={keywords}/>
      <meta property="fb:admins" content={facebookid} />
      {/*<link rel="mask-icon" href={`${favicon}`} color="#21252a" type="image/png"/>*/}
      {/*<link rel="apple-touch-icon" href={`${favicon}`} type="image/png"/>*/}
      
      {/*<!-- Facebook Meta Tags -->/*/}
      <meta property="og:url" content={siteUrl} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={`${title} | ${siteTitle}`} />
      <meta property="og:description" content={description || siteDesc}/>
      <meta property="og:image" itemprop="image" content={`${siteUrl}${image}`}  />
      {/** Twitter Meta Tags */}
      <meta property="twitter:domain" content={siteUrl}/>
      <meta property="twitter:url" content={siteUrl}/>
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={twitterUsername} />
      <meta name="twitter:title" content={`${title} | ${siteTitle}`} />
      <meta name="twitter:description" content={description || siteDesc} />
      <meta name="twitter:image" content={`${siteUrl}${image}`} />
      <meta name="twitter:site" content={twitterUsername}></meta>
      
      {/*<!-- Marcado JSON-LD generado por el Asistente para el marcado de datos estructurados de Google. -->*/}
<script type="application/ld+json">
{`{
  "@context" : "http://schema.org",
  "@type" : "LocalBusiness",
  "name" : "Neurona Servicios",
  "image" : "https://neuronaservicios.com/static/f621e3eb5f4ac6ade6b3b82d2f955253/ee604/Logos_Neurona_Servicios-13.png",
  "email" : "neurona.servicios@gmail.com",
  "address" : {
    "@type" : "PostalAddress",
    "addressLocality" : "Caracas",
    "addressCountry" : "Venezuela"
  },
  "url" : "https://neuronaservicios.com/"
}`}
</script>
    </Helmet>
  )
}

export default SEO
