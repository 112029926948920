import React from "react"
import SocialLinks from "../constants/socialLinks"
import logo from "../assets/Logos_Neurona_Servicios-01.png"
import { Link } from "gatsby"
const Footer = () => {
  return (
    <footer className="footer">
      <div className="left"><Link to="/">
          <img src={logo} alt="logo" />
          </Link>
      </div>
          
      <div className="right">
        <h4>Síguenos en nuestras redes:</h4>
        <SocialLinks styleClass="footer-links"></SocialLinks>
        
      </div>
      <div className="bottom">
      <h3>
          Pensamos soluciones
      </h3>
      <h4>
         &copy;{new Date().getFullYear()}
          <span> Neurona Servicios</span> 
        </h4>
        
      </div>
    </footer>
  )
}

export default Footer
